/**
 * Backend API
 * Card In Move
 *
 * The version of the OpenAPI document: 4.0.2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ChatSettings } from './chat-settings';
import { PushToken } from './push-token';
import { Tag } from './tag';
import { SMTPDetails } from './smtp-details';
import { BillingSettings } from './billing-settings';
import { SocialLogins } from './social-logins';

export interface User {
	id?: string;
	leagueActive?: boolean;
	tags?: Array<Tag>;
	orgStatPerm?: boolean;
	registrationLink?: string;
	referralID?: string;
	referralEmail?: string;
	referredByRegistration?: boolean;
	billingSettings?: BillingSettings;
	chatSettings?: ChatSettings;
	socialSettings?: SocialLogins;
	smtpDetails?: SMTPDetails;
	pushToken?: PushToken;
	deleted?: boolean;
}
